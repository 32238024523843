import { getMessaging as getMessagingOriginal } from 'firebase/messaging';

import getFirebaseApp from '~/libs/fcm/getFirebaseApp';

let messaging: null | ReturnType<typeof getMessagingOriginal> = null;

const getMessaging = () => {
    if (messaging === null) {
        messaging = getMessagingOriginal(getFirebaseApp());
    }
    return messaging;
};

export default getMessaging;
