import { all, call, select, takeLatest } from 'typed-redux-saga';

import { collectorLogAPI } from '~/apis/log';
import LogName from '~/constants/LogName';
import Status from '~/constants/Status';
import { RootReducerState } from '~/modules';
import { manageFCMAction } from '~/modules/ManageFCMModule';

/**
 * 알림 활성화 클릭 로그 수집
 *
 * @yields
 */
function* EnableClickLogSaga(): Generator {
    type EnableClickLogActionType = ReturnType<
        typeof manageFCMAction.setEnabled
    >;

    yield takeLatest<EnableClickLogActionType>(
        manageFCMAction.setEnabled.type,
        function* ({ payload: enable }) {
            // reducer 값 조회
            const permission = yield* select(
                ({ allowFCMReducer }: RootReducerState) => {
                    if (
                        allowFCMReducer.status !== Status.SUCCESS ||
                        !allowFCMReducer.data
                    )
                        return false;

                    return allowFCMReducer.data.permission;
                }
            );

            if (permission === false) return;

            yield* call<typeof collectorLogAPI>(
                collectorLogAPI,
                LogName.CLICK,
                {
                    codeType: 'pc_alaram_chg_status',
                    enable,
                    permission
                }
            );
        }
    );
}

/**
 * 로그 수집 saga
 *
 * @yields
 */
function* LogActionSaga(): Generator {
    yield* all([call(EnableClickLogSaga)]);
}

export default LogActionSaga;
