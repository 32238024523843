import React, { FC } from 'react';

import ScriptHelmet from '~/components/atoms/ScriptHelmet';
import { STATIC_HOST } from '~/constants/hosts';

const LeftLnb: FC = () => {
    const handleLoadScript = () => {
        const script = document.createElement('script');

        script.innerHTML = `
                        var oLeftLnbDom = document.getElementById('leftLnb');
                        Sub_Left_Gnb();
                    `;
        document.body.appendChild(script);
    };

    return (
        <>
            <ScriptHelmet>
                <script
                    src={`${STATIC_HOST}/asset/service/gnb/lnb.js`}
                    onLoad={handleLoadScript}
                />
            </ScriptHelmet>
            <div className="sub_left" id="leftLnb" />
        </>
    );
};

export default LeftLnb;
