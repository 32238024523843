import debug from 'debug';
import { isSupported } from 'firebase/messaging';

import detectBrowser from '~/libs/detectBrowser';

const supportDebug = debug('noti:fcm-support');

const notSupportBrowser = ['firefox'];

const checkFCMSupport = async () => {
    const browser = detectBrowser();
    if (!browser) {
        supportDebug('can not detected browser.');
        return false;
    }

    // 브라우저 지원 여부 확인
    const browserName = browser.name;
    if (notSupportBrowser.includes(browserName)) {
        supportDebug(`'${browserName}' browser is not supported.`);
        return false;
    }

    supportDebug('check fcm support.');
    const supported = await isSupported();
    supportDebug('fcm support:', supported);
    return supported;
};

export default checkFCMSupport;
