import React, { FC } from 'react';

import Footer from '~/components/organisms/subGnb/Footer';
import Header from '~/components/organisms/subGnb/Header';
import LeftLnb from '~/components/organisms/subGnb/LeftLnb';

const SubPageLayout: FC = ({ children }) => {
    return (
        <>
            {/* header */}
            <Header />

            {/* body */}
            <div className="sub_whole">
                {/* lnb */}
                <LeftLnb />

                {/* 실제 contents 영역 */}
                <div className="sub_contents">{children}</div>
            </div>

            {/* footer */}
            <Footer />
        </>
    );
};

export default SubPageLayout;
