import debug from 'debug';
import { getToken } from 'firebase/messaging';

import { VAPID } from '~/constants/firebaseConfig';
import getMessaging from '~/libs/fcm/browser/getMessaging';
import getSWRegistration from '~/libs/fcm/browser/getSWRegistration';

const tokenDebug = debug('noti:fetch-token');

/**
 * 토큰 조회
 *
 * @returns {Promise<string>} 신규/기존 토큰 값
 */
export const fetchTokenAPI = async (): Promise<string> => {
    const messaging = getMessaging();
    const registration = await getSWRegistration();

    tokenDebug('start to fetch token from FCM');
    const token = await getToken(messaging, {
        vapidKey: VAPID,
        serviceWorkerRegistration: registration
    });
    tokenDebug('token', token);

    return token;
};
