import Warning from '~/errors/Warning';

class StorageError extends Warning {
    constructor(message: string) {
        super(message);
        this.productionMessage =
            '저장소를 가져오지 못했습니다. 브라우저 또는 권한문제일 수 있습니다. 잠시 후 다시 시도해주세요.';

        this.name = 'StorageError';
    }
}

export default StorageError;
