import ContentsSettingType from '~/constants/ContentsSettingType';

export interface ContentsSettingValue {
    [key: string]: boolean;
}

export type ContentsSettingData = {
    [name in ContentsSettingType]: ContentsSettingValue;
};

export const isContentsSettingValue = (
    item: unknown
): item is ContentsSettingValue => {
    const settingValue = item as ContentsSettingValue;
    if (typeof settingValue !== 'object') return false;

    for (const key in settingValue) {
        if (typeof key !== 'string' || typeof settingValue[key] !== 'boolean')
            return false;
    }

    return true;
};

export const isContentsSettingData = (
    item: unknown
): item is ContentsSettingData => {
    const settingData = item as ContentsSettingData;
    if (typeof settingData !== 'object') return false;

    let validate = true;
    Object.values(ContentsSettingType).forEach((type) => {
        if (!isContentsSettingValue(settingData[type])) {
            validate = false;
            return false;
        }
    });

    return validate;
};
