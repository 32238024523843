import { initializeApp } from 'firebase/app';

import firebaseConfig from '~/constants/firebaseConfig';

let firebaseApp: null | ReturnType<typeof initializeApp> = null;

const getFirebaseApp = () => {
    if (firebaseApp === null) firebaseApp = initializeApp(firebaseConfig);
    return firebaseApp;
};

export default getFirebaseApp;
