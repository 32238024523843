import i18next from 'i18next';
import { all, call, put, select, take, takeLatest } from 'typed-redux-saga';

import {
    fetchContentsSettingAPI,
    resetContentsSettingAPI,
    updateContentsSettingAPI
} from '~/apis/notification/setting';
import ContentsSettingType from '~/constants/ContentsSettingType';
import Status from '~/constants/Status';
import errorHandler from '~/libs/errorHandler';
import { RootReducerState } from '~/modules';
import { contentsSettingAction } from '~/modules/ContentsSettingModule';
import { ContentsSettingData } from '~/types/ContentsSettingData';

/**
 * contents setting 을 가져오는 saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* FetchContentsSettingSaga(): Generator {
    type FetchContentsSettingActionType = ReturnType<
        typeof contentsSettingAction.fetchData
    >;

    yield takeLatest<FetchContentsSettingActionType>(
        contentsSettingAction.fetchData.type,
        function* ({ payload: deviceID }) {
            try {
                const response = yield* call(fetchContentsSettingAPI, deviceID);

                yield put(contentsSettingAction.fetchDataSuccess(response));
            } catch (error) {
                errorHandler(
                    error,
                    'error occurred in fetch contents setting saga.'
                );
            }
        }
    );
}

/**
 * 콘텐츠 초기 설정 조회
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* ResetContentsSettingSaga(): Generator {
    type ResetContentsSettingActionType = ReturnType<
        typeof contentsSettingAction.resetSetting
    >;

    yield takeLatest<ResetContentsSettingActionType>(
        contentsSettingAction.resetSetting.type,
        function* () {
            try {
                // 초기 설정 조회
                const response = yield* call(resetContentsSettingAPI);

                // 데이터 세팅
                yield* put(contentsSettingAction.resetSettingSuccess(response));
            } catch (error) {
                errorHandler(
                    error,
                    'error occurred in reset contents setting saga.'
                );
            }
        }
    );
}

/**
 * contents setting 저장
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateContentsSettingSaga(): Generator {
    type UpdateContentsSettingActionType = ReturnType<
        typeof contentsSettingAction.updateSetting
    >;

    while (true) {
        yield* take<UpdateContentsSettingActionType>(
            contentsSettingAction.updateSetting.type
        );

        try {
            const result = yield* select(
                ({
                    manageFCMReducer,
                    contentsSettingReducer
                }: RootReducerState) => {
                    // 값 조회
                    const {
                        status: contentsSettingStatus,
                        data: contentsSettingData
                    } = contentsSettingReducer;

                    const { status: manageFCMStatus, data: manageFCMData } =
                        manageFCMReducer;

                    const { deviceID, token, locale, osVersion, appVersion } =
                        manageFCMData;

                    // 값 필터링
                    if (
                        manageFCMStatus !== Status.SUCCESS ||
                        contentsSettingStatus !== Status.SUCCESS ||
                        !deviceID ||
                        !contentsSettingData
                    )
                        return false;

                    return {
                        fcmData: {
                            deviceID,
                            token,
                            locale,
                            osVersion,
                            appVersion
                        },
                        contentsSettingData
                    };
                }
            );

            if (!result) continue;

            const { fcmData, contentsSettingData } = result;

            // parameter 세팅
            const settings: Partial<ContentsSettingData> = {};
            Object.values(ContentsSettingType).forEach((type) => {
                settings[type] = contentsSettingData[type];
            });

            // update api 호출
            yield* call(
                updateContentsSettingAPI,
                {
                    deviceID: fcmData.deviceID,
                    appVersion: fcmData.appVersion || '',
                    locale: fcmData.locale || '',
                    osVersion: fcmData.osVersion || '',
                    token: fcmData.token || ''
                },
                settings
            );

            // reducer 에 반영
            yield* put(contentsSettingAction.updateSettingSuccess());

            // message
            alert(i18next.t('설정이 저장되었습니다.'));
        } catch (error) {
            errorHandler(
                error,
                'error occurred in update contents setting saga.'
            );
        }
    }
}

/**
 * Contents setting 에 대한 saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* ContentsSettingSaga(): Generator {
    yield all([
        call(FetchContentsSettingSaga),
        call(UpdateContentsSettingSaga),
        call(ResetContentsSettingSaga)
    ]);
}

export default ContentsSettingSaga;
