import {
    ContentsSettingData,
    isContentsSettingData
} from '~/types/ContentsSettingData';

export interface ContentsSettingItem {
    key: string;
    title: string;
    sub_title: string;
}

export interface ContentsSettingGroup {
    group_title: string;
    list: ContentsSettingItem[];
}

export type ContentsSettingResponse = ContentsSettingData & {
    info: ContentsSettingGroup[];
};

export const isContentsSettingItem = (
    item: unknown
): item is ContentsSettingItem => {
    const settingItem = item as ContentsSettingItem;
    if (typeof settingItem !== 'object') return false;

    const { title, key, sub_title: subTitle } = settingItem;

    return (
        typeof key === 'string' &&
        typeof title === 'string' &&
        typeof subTitle === 'string'
    );
};

export const isContentsSettingGroup = (
    item: unknown
): item is ContentsSettingGroup => {
    const settingGroup = item as ContentsSettingGroup;
    if (typeof settingGroup !== 'object') return false;

    const { group_title: groupTitle, list } = settingGroup;
    return (
        typeof groupTitle === 'string' &&
        Array.isArray(list) &&
        list.every(isContentsSettingItem)
    );
};

export const isContentsSettingResponse = (
    item: unknown
): item is ContentsSettingResponse => {
    const settingResponse = item as ContentsSettingResponse;
    if (typeof settingResponse !== 'object') return false;

    const { info } = settingResponse;
    return (
        isContentsSettingData(settingResponse) &&
        Array.isArray(info) &&
        info.every(isContentsSettingGroup)
    );
};
