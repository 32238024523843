import { withCallback } from 'redux-saga-callback';
import { all, call, put, takeLatest } from 'typed-redux-saga';

import NotificationPermissionCustom from '~/constants/NotificationPermission';
import errorHandler from '~/libs/errorHandler';
import checkFCMSupport from '~/libs/fcm/checkFCMSupport';
import { allowFCMAction } from '~/modules/AllowFCMModule';

/**
 * fcm 지원 여부 확인
 *
 * @returns {Generator} redux saga
 * @yields
 */
function* CheckAllowSaga(): Generator {
    type CheckAllowActionType = ReturnType<typeof allowFCMAction.checkAllow>;
    yield takeLatest<CheckAllowActionType>(
        allowFCMAction.checkAllow.type,
        withCallback(function* ({ payload: request }) {
            try {
                const support = yield* call(checkFCMSupport);

                let permission:
                    | NotificationPermissionCustom
                    | NotificationPermission =
                    NotificationPermissionCustom.DENIED;
                if (support && window.Notification) {
                    permission = window.Notification.permission;
                    if (request) {
                        permission = yield* call(
                            window.Notification.requestPermission
                        );
                    }
                }

                yield* put(
                    allowFCMAction.checkAllowSuccess({ support, permission })
                );
            } catch (error) {
                errorHandler(error, 'Error occurred in check allow saga.');
            }
        })
    );
}

/**
 * fcm 허용에 대한 saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* AllowFCMSaga(): Generator {
    yield* all([call(CheckAllowSaga)]);
}

export default AllowFCMSaga;
