import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

const ToastRoot = document.getElementById('toastContainer');

const ToastPortal: FC = ({ children }) => {
    const element = document.createElement('div');

    useEffect(() => {
        if (!ToastRoot) return;

        ToastRoot.appendChild(element);

        return () => {
            ToastRoot.removeChild(element);
        };
    }, [element]);

    if (!element) return null;
    return createPortal(children, element);
};

export default ToastPortal;
