import {
    createActionCreators,
    createReducerFunction,
    ImmerReducer
} from 'immer-reducer';

import ContentsSettingType from '~/constants/ContentsSettingType';
import Status from '~/constants/Status';
import { ContentsSettingData } from '~/types/ContentsSettingData';
import { ContentsSettingResponse } from '~/types/response/ContentsSettingResponse';

interface PreLoadedState {
    status: Omit<Status, 'LOADED'>;
    changed: false;
    data: null;
}

interface LoadedState {
    status: Status.SUCCESS;
    changed: boolean;
    data: ContentsSettingResponse;
}

export type ContentsSettingState = PreLoadedState | LoadedState;

const initializeState: ContentsSettingState = {
    status: Status.INITIAL,
    changed: false,
    data: null
};

const prevData: Partial<ContentsSettingData> = {};

class ContentsSettingModule extends ImmerReducer<ContentsSettingState> {
    reset() {
        this.draftState = initializeState;
    }

    // 콘텐츠 설정 요청
    fetchData(deviceID: string) {
        this.draftState = {
            status: Status.LOADING,
            changed: false,
            data: null
        };
    }

    // 콘텐츠 설정 조회 완료
    fetchDataSuccess(response: ContentsSettingResponse) {
        this.draftState = {
            status: Status.SUCCESS,
            changed: false,
            data: response
        };

        Object.values(ContentsSettingType).forEach((key) => {
            prevData[key] = response[key];
        });
    }

    // 콘텐츠 설정 값 변경
    changeSetting(type: ContentsSettingType, key: string, setting: boolean) {
        if (this.draftState.status !== Status.SUCCESS || !this.draftState.data)
            return;

        this.draftState.data[type][key] = setting;
        this.draftState.changed = this.checkChanged();
    }

    // 콘텐츠 설정 값 업데이트 요청
    updateSetting() {
        if (this.draftState.status !== Status.SUCCESS || !this.draftState.data)
            return;

        Object.values(ContentsSettingType).forEach((key) => {
            prevData[key] = JSON.parse(
                JSON.stringify(this.draftState.data?.[key])
            );
        });
    }

    // 콘텐츠 설정 값 업데이트 완료
    updateSettingSuccess() {
        this.draftState.changed = this.checkChanged();
    }

    // 콘텐츠 설정 기존 값으로 초기화
    resetSetting() {}
    resetSettingSuccess(resetData: ContentsSettingData) {
        if (this.draftState.status !== Status.SUCCESS || !this.draftState.data)
            return;

        this.draftState.data = {
            ...this.draftState.data,
            ...resetData
        };
        this.draftState.changed = this.checkChanged();
    }

    // push disable 시 push setting 초기화
    resetPushSetting() {
        const { status, data } = this.draftState;
        if (status !== Status.SUCCESS || !data) return;

        const prevSetting = prevData[ContentsSettingType.PUSH];
        if (!prevSetting || !this.draftState.data) return;

        this.draftState.data[ContentsSettingType.PUSH] = prevSetting;

        this.draftState.changed = this.checkChanged();
    }

    /**
     * 변경된 값이 있는지 확인
     *
     * @returns {boolean} 변경된 값이 있는지에 대한 값
     * @private
     */
    private checkChanged(): boolean {
        const { status, data } = this.draftState;
        if (status !== Status.SUCCESS || !data) return false;

        let changed = false;
        Object.values(ContentsSettingType).forEach((type) => {
            const prevSetting = prevData[type];
            const currentSetting = data[type];
            if (!prevSetting) return true;

            for (const key in currentSetting) {
                if (currentSetting[key] !== prevSetting[key]) {
                    changed = true;
                    return false;
                }
            }
        });

        return changed;
    }
}

export const contentsSettingReducer = createReducerFunction(
    ContentsSettingModule,
    initializeState
);
export const contentsSettingAction = createActionCreators(
    ContentsSettingModule
);
