import axiosBase, { AxiosResponse } from 'axios';
import i18next from 'i18next';
import querystring from 'qs';

import { LIVE_HOST } from '~/constants/hosts';
import HTTPError from '~/errors/HTTPError';

const axios = axiosBase.create({
    baseURL: LIVE_HOST,
    timeout: 10000,
    paramsSerializer: (params) => querystring.stringify(params),
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    withCredentials: true
});

const isAxiosResponse = (item: unknown): item is AxiosResponse<typeof item> => {
    const response = item as AxiosResponse<typeof item>;
    return (
        response &&
        typeof response.data !== 'undefined' &&
        typeof response.status === 'number' &&
        typeof response.config !== 'undefined'
    );
};

// request middleware
axios.interceptors.request.use((request) => {
    if (request.data) {
        request.data = querystring.stringify(request.data);
    }

    return request;
});

axios.interceptors.response.use(
    (response) => response,
    (error: Error) => {
        // axios error 인지 확인
        if (!axiosBase.isAxiosError(error)) {
            throw error;
        }

        // axios response 확인
        if (!isAxiosResponse(error.response)) {
            throw error;
        }

        const { status, data } = error.response;
        const errorResponse = {
            MESSAGE:
                data.MESSAGE ||
                i18next.t('오류가 발생하였습니다. 잠시 후 다시 시도해 주세요.'),
            RESULT: data.RESULT || -100
        };

        throw new HTTPError(status, errorResponse);
    }
);

export default axios;
