import 'raf/polyfill';

import { enableAllPlugins } from 'immer';
import nodeWindowPolyfill from 'node-window-polyfill';

import { IS_BROWSER } from '~/constants/etc';

if (!IS_BROWSER) {
    nodeWindowPolyfill.register();

    global.document = {
        cookie: '',
        getElementById(elementId: string): HTMLElement | null {
            return null;
        },
        createElement<K extends keyof HTMLElementTagNameMap>(
            tagName: K,
            options?: ElementCreationOptions
        ): HTMLElementTagNameMap[K] | null {
            return null;
        },
        addEventListener<K extends keyof DocumentEventMap>(
            type: K,
            listener: (this: Document, ev: DocumentEventMap[K]) => any,
            options?: boolean | AddEventListenerOptions
        ) {}
    } as Document;

    window.location = {
        search: ''
    } as Location;

    global.location = window.location;
    global.navigator = window.navigator;
    global.localStorage = window.localStorage;
} else {
    // immer 에 대한 es5 세팅
    enableAllPlugins();
}
