import { FirebaseOptions } from '@firebase/app';

export const VAPID = process.env.GATSBY_VAPID;

const firebaseConfig: FirebaseOptions = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID
};

export default firebaseConfig;
