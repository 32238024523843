import { SERVER_HOST } from '~/constants/hosts';
import CustomError from '~/errors/CustomError';

/**
 * service worker registration 등록 및 객체 조회
 *
 * @returns {Promise<ServiceWorkerRegistration>} service worker registration 객체
 */
const getSWRegistration = async (): Promise<ServiceWorkerRegistration> => {
    const scriptUrl = `${SERVER_HOST}/fcm-service-worker.js`;

    const serviceWorkerContainer = navigator.serviceWorker;

    // 기존 동작중인 서비스 워커 조회
    let registration = await serviceWorkerContainer.getRegistration(scriptUrl);

    // 동작중인 워커가 없을 경우 새로 register
    if (!registration || !registration.active) {
        registration = await navigator.serviceWorker.register(scriptUrl);
    }

    // service worker 조회
    const serviceWorker =
        registration.installing || registration.waiting || registration.active;
    if (!serviceWorker) {
        throw new CustomError('not register service worker.');
    }

    // active 상태 될때까지 대기
    await new Promise<void>((resolve) => {
        if (serviceWorker.state === 'activated') {
            resolve();
            return;
        }

        serviceWorker.addEventListener('statechange', (event) => {
            const serviceWorker = event.currentTarget as ServiceWorker;

            if (['activated', 'redundant'].includes(serviceWorker.state)) {
                resolve();
            }
        });
    });

    return registration;
};

export default getSWRegistration;
