import { all, call } from 'typed-redux-saga';

import AllowFCMSaga from '~/sagas/AllowFCMSaga';
import ContentsSettingSaga from '~/sagas/ContentsSettingSaga';
import LocalStorageSaga from '~/sagas/LocalStorageSaga';
import LogActionSaga from '~/sagas/LogActionSaga';
import ManageFCMSaga from '~/sagas/ManageFCMSaga';

/**
 * root saga
 *
 * @yields
 * @returns {Generator} saga generator
 */
function* rootSaga(): Generator {
    yield all([
        call(LocalStorageSaga),
        call(ManageFCMSaga),
        call(AllowFCMSaga),
        call(ContentsSettingSaga),
        call(LogActionSaga)
    ]);
}

export default rootSaga;
