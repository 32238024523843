import {
    createActionCreators,
    createReducerFunction,
    ImmerReducer
} from 'immer-reducer';

import Status from '~/constants/Status';

interface AllowState {
    support: boolean;
    permission: NotificationPermission;
}

interface AllowFCMModuleState {
    status: Status;
    data: AllowState | null;
}

const initializeState: AllowFCMModuleState = {
    status: Status.INITIAL,
    data: null
};

class AllowFCMModule extends ImmerReducer<AllowFCMModuleState> {
    checkAllow(request: boolean) {
        this.draftState.status = Status.LOADING;
    }

    checkAllowSuccess(data: AllowState) {
        this.draftState = {
            status: Status.SUCCESS,
            data
        };
    }
}

export const allowFCMReducer = createReducerFunction(
    AllowFCMModule,
    initializeState
);
export const allowFCMAction = createActionCreators(AllowFCMModule);
