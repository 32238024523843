import React, {
    Children,
    ComponentProps,
    FC,
    isValidElement,
    useCallback
} from 'react';
import { Helmet } from 'react-helmet';

const scriptLoadEvent: Record<string, GlobalEventHandlers> = {};

interface ScriptHelmetProps extends ComponentProps<typeof Helmet> {}

const ScriptHelmet: FC<ScriptHelmetProps> = ({
    children,
    script,
    ...props
}) => {
    type handleAddScriptType = ComponentProps<
        typeof Helmet
    >['onChangeClientState'];
    const handleAddScript: handleAddScriptType = useCallback(
        (newState, { scriptTags }) => {
            scriptTags?.forEach((script: HTMLScriptElement) => {
                const onLoad = scriptLoadEvent[script.src];
                if (typeof onLoad === 'function') {
                    script.onload = onLoad;
                }
            });
        },
        []
    );

    script?.forEach((script) => {
        const onLoad = script.onload,
            src = script.src;
        if (typeof onLoad === 'function' && typeof src === 'string') {
            scriptLoadEvent[src] = onLoad;
        }
    });

    Children.forEach(children, (child) => {
        if (isValidElement(child) && child.type === 'script') {
            const { src, onLoad } = child.props;
            if (typeof onLoad === 'function' && typeof src === 'string') {
                scriptLoadEvent[src] = onLoad;
            }
        }
    });

    return (
        <Helmet
            onChangeClientState={handleAddScript}
            script={script}
            {...props}>
            {children}
        </Helmet>
    );
};

export default ScriptHelmet;
