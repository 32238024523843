import {
    ContentsSettingData,
    isContentsSettingData
} from '~/types/ContentsSettingData';

type ResetSettingResponse = ContentsSettingData;

export const isResetSettingResponse = isContentsSettingData;

export default ResetSettingResponse;
