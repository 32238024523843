enum Locale {
    KO_KR = 'ko_KR',
    EN_UR = 'en_US',
    JA_JP = 'ja_JP',
    TH_TH = 'th_TH',
    VI_VN = 'vi_VN',
    ZH_CN = 'zh_CN',
    ZH_TW = 'zh_TW'
}

export const getLocale = (rawLocale: string): Locale => {
    const rawLocaleList = {
        [Locale.KO_KR]: ['ko'],
        [Locale.EN_UR]: ['en', 'en-US'],
        [Locale.JA_JP]: ['ja'],
        [Locale.TH_TH]: ['th'],
        [Locale.VI_VN]: ['vi'],
        [Locale.ZH_CN]: ['zh', 'zh-CN'],
        [Locale.ZH_TW]: ['zh-TW']
    };

    let locale: Locale = Locale.KO_KR;
    Object.entries(rawLocaleList).forEach(([currentLocale, rawLocaleList]) => {
        if (rawLocaleList.includes(rawLocale)) {
            locale = currentLocale as Locale;
            return false;
        }
    });

    return locale;
};

export default Locale;
