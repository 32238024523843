import Warning from '~/errors/Warning';

class ResponseError extends Warning {
    response: unknown;

    constructor(message: string, response?: unknown) {
        super(message);
        this.response = response;
        this.productionMessage =
            '잘못된 응답입니다. 잠시 후 다시 사도해주세요.';

        this.name = 'ResponseError';
    }
}

export default ResponseError;
