import React, { FC, useMemo } from 'react';

import HeaderPortal from '~/components/atoms/HeaderPortal';
import ScriptHelmet from '~/components/atoms/ScriptHelmet';
import { MEMBER_HOST, STATIC_HOST, WWW_HOST } from '~/constants/hosts';

const Header: FC = () => {
    const handleScriptLoaded = () => {
        const script = document.createElement('script');

        // header 생성
        script.innerHTML = `
            var isSearchVisible = false;
            var oGnbDom = document.getElementById('topInnerHeader');
            oGnb.setSubGnb('notification', true, '');
        `;
        document.body.appendChild(script);
    };

    const handleSearchScriptLoaded = () => {
        if (document.getElementById('afSearcharea')) return;

        const searchArea = document.createElement('div');
        searchArea.id = 'afSearcharea';
        document.getElementById('topInnerHeader')?.appendChild(searchArea);
    };

    return useMemo(
        () => (
            <>
                <ScriptHelmet>
                    <script
                        src={`${MEMBER_HOST}/script/gnb/gnb_global_member.js`}
                        onLoad={handleScriptLoaded}
                        defer={true}
                    />
                    <script
                        src={`${STATIC_HOST}/asset/app/search/dist/umd/index.js`}
                        className="searchBox"
                        data-type="total"
                        data-location="sub"
                        onLoad={handleSearchScriptLoaded}
                        defer={true}
                    />
                </ScriptHelmet>
                <HeaderPortal>
                    <header id="serviceHeader" className="headerSub">
                        <div id="topInnerHeader" className="innerHeader">
                            <h1 id="afLogo">
                                <a href={WWW_HOST} target="_top">
                                    afreecaTV
                                </a>
                            </h1>
                        </div>
                    </header>
                </HeaderPortal>
            </>
        ),
        []
    );
};

export default Header;
