interface FullFCMDataResponse {
    locale: string;
    app_version: string;
    os_version: string;
    token: string;
    notification: string;
}

type FCMDataResponse = FullFCMDataResponse | null;

export const isFCMDataResponse = (item: unknown): item is FCMDataResponse => {
    const fcmDataResponse = item as FCMDataResponse;
    if (fcmDataResponse === null) return true;

    if (typeof fcmDataResponse !== 'object') return false;
    return (
        typeof fcmDataResponse.locale === 'string' &&
        typeof fcmDataResponse.app_version === 'string' &&
        typeof fcmDataResponse.os_version === 'string' &&
        typeof fcmDataResponse.token === 'string' &&
        typeof fcmDataResponse.notification === 'string'
    );
};

export default FCMDataResponse;
