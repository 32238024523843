import React, { FC } from 'react';

import SubPageLayout from '~/components/layouts/SubPageLayout';

const ErrorFallback: FC = () => {
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <SubPageLayout>
            <div className="alarmSetError">
                <strong>요청하신 페이지를 표시할 수 없습니다.</strong>
                <span>
                    현재 시스템에 문제가 발생하였습니다.
                    <br />
                    잠시 후 다시 이용해주세요.
                </span>
                <a href={'javascript:void;'} onClick={handleReload}>
                    다시 접속하기
                </a>
            </div>
        </SubPageLayout>
    );
};

export default ErrorFallback;
