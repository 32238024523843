import '~/libs/polyfill/register';
import '~/libs/i18n';
import 'react-toastify/dist/ReactToastify.css';

import i18next from 'i18next';
import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Helmet from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Flip, ToastContainer } from 'react-toastify';

import ToastPortal from '~/components/atoms/ToastPortal';
import ErrorFallback from '~/components/organisms/ErrorFallback';
import errorHandler from '~/libs/errorHandler';
import store from '~/modules';

const ApplicationWrapper: FC = ({ children }) => {
    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <ErrorBoundary
                    onError={(error) => errorHandler(error)}
                    FallbackComponent={ErrorFallback}>
                    {/* 헤더 등록 */}
                    <Helmet title="AfreecaTV notification register" />

                    {children}

                    <ToastPortal>
                        <ToastContainer
                            position="bottom-center"
                            autoClose={1500}
                            hideProgressBar
                            closeOnClick
                            pauseOnFocusLoss
                            pauseOnHover
                            newestOnTop
                            transition={Flip}
                        />
                    </ToastPortal>
                </ErrorBoundary>
            </I18nextProvider>
        </Provider>
    );
};

export default ApplicationWrapper;
