import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';

const HeaderRoot = document.getElementById('header');

const HeaderPortal: FC = ({ children }) => {
    const element = document.createElement('div');

    useEffect(() => {
        if (!HeaderRoot) return;

        HeaderRoot.appendChild(element);

        return () => {
            HeaderRoot.removeChild(element);
        };
    }, [element]);

    if (!element) return null;
    return createPortal(children, element);
};

export default HeaderPortal;
