import { parse } from 'accept-language-parser';
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { IS_PRODUCTION } from '~/constants/etc';
import TranslateLocales, {
    getAllowLocales
} from '~/constants/TranslateLocales';

const languageDetector = new I18nextBrowserLanguageDetector(null, {
    caches: [],
    convertDetectedLanguage: (originalLang) => {
        const [{ code, region }] = parse(originalLang);
        let lang = code;
        if (region) lang = `${lang}_${region.toUpperCase()}`;

        // 중국어 예외처리
        if (code === 'zh') {
            if (['zh_HK', 'zh_MO', 'zh_HANT'].includes(lang))
                lang = TranslateLocales.ZH_TW;
            else lang = TranslateLocales.ZH_CN;
        }

        return lang;
    }
});

i18next
    .use(I18NextHttpBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: TranslateLocales.EN,
        debug: false,
        interpolation: { escapeValue: false },
        backend: {
            loadPath: '{{lng}}',
            request: async (options, url, payload, callback) => {
                try {
                    const response = await import(`../locales/${url}`);

                    callback(null, { status: 200, data: response.default });
                } catch (error) {
                    if (!IS_PRODUCTION && error instanceof Error) {
                        console.warn(error.message);
                    }
                }
            }
        },

        supportedLngs: getAllowLocales(),
        react: { useSuspense: false }
    })
    .then();
