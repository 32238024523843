enum LocalStorageKey {
    FCM_TOKEN = 'fcm-token',
    NOTI_DEVICE_ID = 'noti-device-id',
    NOTI_ENABLED = 'noti-enabled',
    NOTI_LOCALE = 'noti-locale',
    NOTI_OS_VERSION = 'noti-os-version',
    NOTI_APP_VERSION = 'noti-app-version',
    NOTI_USER_INFO = 'noti-user-info'
}

export default LocalStorageKey;
