import {
    createActionCreators,
    createReducerFunction,
    ImmerReducer
} from 'immer-reducer';

interface LocalStorageModuleState {
    [key: string]: string | null | undefined;
}

const initializeState: LocalStorageModuleState = {};

class LocalStorageModule extends ImmerReducer<LocalStorageModuleState> {
    reset() {
        this.draftState = initializeState;
    }

    /**
     * localstorage 에 값 다중 저장
     *
     * @param {LocalStorageModuleState} item update 할 item 리스트
     */
    setItems(item: { [key: string]: string | null }) {}

    /**
     * localstorage 에 값 저장
     *
     * @param {string} key local storage key
     * @param {string} value 저장할 값
     * @returns {void}
     */
    setItem(key: string, value: string | null) {}
    setItemSuccess(key: string, value: string | null) {
        if (this.draftState[key] === value) return;
        this.draftState[key] = value;
    }

    /**
     * localstorage 값으로 초기 세팅
     *
     * @param {string} key local storage key
     */
    initializeItem(key: string) {}
}

export const localStorageReducer = createReducerFunction(
    LocalStorageModule,
    initializeState
);
export const localStorageAction = createActionCreators(LocalStorageModule);
