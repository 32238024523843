import { all, call, put, select, take } from 'typed-redux-saga';

import getStorage from '~/libs/getStorage';
import { RootReducerState } from '~/modules';
import { localStorageAction } from '~/modules/LocalStorageModule';

/**
 * 아이템 값 변경 시 localstorage 반영
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateItemSaga(): Generator {
    type UpdateItemActionType = ReturnType<typeof localStorageAction.setItem>;

    while (true) {
        // 이전 값 세팅
        const storedState = (yield select()) as RootReducerState;

        // setItem 실행될때까지 대기
        const {
            payload: [key, value]
        } = yield* take<UpdateItemActionType>(localStorageAction.setItem.type);

        // 이전 값 조회
        const storage = getStorage();
        const reducerValue = storedState?.localStorageReducer[key];
        const storedValue = storage.getItem(key);

        // localstorage 에 데이터 세팅
        if (storedValue !== value) {
            if (value === null) storage.removeItem(key);
            else storage.setItem(key, value);
        }

        // reducer 에 값 세팅
        if (reducerValue !== value)
            yield put(localStorageAction.setItemSuccess(key, value));
    }
}

/**
 * 다중 localstorage update saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateItemsSaga(): Generator {
    type UpdateItemsActionType = ReturnType<typeof localStorageAction.setItems>;

    while (true) {
        // setItems 실행될때까지 대기
        const { payload } = yield* take<UpdateItemsActionType>(
            localStorageAction.setItems.type
        );

        // 각 data 에 대해 setItem 호출
        for (const key in payload) {
            const value = payload[key];
            yield put(localStorageAction.setItem(key, value));
        }
    }
}

/**
 * 최초 값 조회 시 reducer 세팅
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* InitializeSaga(): Generator {
    type InitializeItemActionType = ReturnType<
        typeof localStorageAction.initializeItem
    >;

    while (true) {
        // 이전 값 세팅
        const storedState = (yield select()) as RootReducerState;

        // initializeItem 실행될때까지 대기
        const { payload: key } = yield* take<InitializeItemActionType>(
            localStorageAction.initializeItem.type
        );

        // 이전 값 / 현재 값 조회
        const storedValue = storedState?.localStorageReducer[key];
        const currentValue = getStorage().getItem(key);

        // 기존 값과 동일할 경우 pass
        if (storedValue === currentValue) continue;

        // reducer 에 값 세팅
        yield put(localStorageAction.setItem(key, currentValue));
    }
}

/**
 * local storage saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* LocalStorageSaga(): Generator {
    yield all([
        call(UpdateItemSaga),
        call(UpdateItemsSaga),
        call(InitializeSaga)
    ]);
}

export default LocalStorageSaga;
