import debug from 'debug';

import ResponseError from '~/errors/ResponseError';
import axios from '~/libs/axios';
import APIResponse from '~/types/response/APIResponse';
import FCMDataResponse, {
    isFCMDataResponse
} from '~/types/response/FCMDataResponse';

const tokenDebug = debug('noti:token-api');

/**
 * data 세팅
 *
 * @param {string} deviceID 조회할 device id
 * @returns {Promise<FCMDataResponse>} fcm data
 */
export const syncFCMDataApi = async (
    deviceID: string
): Promise<FCMDataResponse> => {
    tokenDebug('syncFCMDataApi', deviceID);
    const { data: response } = await axios.get<APIResponse<FCMDataResponse>>(
        '/api/fcm/index.php',
        {
            params: {
                work: 'TOKEN',
                deviceID
            }
        }
    );

    // 지정한 데이터가 맞는지 확인
    if (!isFCMDataResponse(response.DATA)) {
        throw new ResponseError(
            'Mismatch responses in sync fcm data api.',
            response
        );
    }

    return response.DATA;
};

interface UpdateFCMDataAPIParam {
    token: string;
    deviceID: string;
    locale: string;
    osVersion: string;
    appVersion: string;
}

/**
 * database 에 token data update 요청
 *
 * @param {UpdateFCMDataAPIParam} tokenData register 할 token 정보들
 * @param {string} tokenData.token 업데이트 할 token
 * @param {string} tokenData.deviceID device id
 * @param {string} tokenData.locale browser locale
 * @param {string} tokenData.osVersion os version
 * @param {string} tokenData.appVersion app version
 */
export const updateFCMDataAPI = async ({
    deviceID,
    token,
    locale,
    osVersion,
    appVersion
}: UpdateFCMDataAPIParam): Promise<void> => {
    tokenDebug(
        'updateFCMDataAPI',
        deviceID,
        token,
        locale,
        osVersion,
        appVersion
    );
    await axios.post('/api/fcm/index.php', {
        work: 'TOKEN',
        deviceID,
        token,
        locale,
        osVersion,
        appVersion
    });
};

/**
 * fcm 활성화 여부 변경
 *
 * @param {string} deviceID 변경 요청할 device id
 * @param {boolean} enabled 활성화 여부
 */
export const changeFCMEnabledAPI = async (
    deviceID: string,
    enabled: boolean
): Promise<void> => {
    tokenDebug('changeFCMEnabledAPI', deviceID, enabled);
    await axios.post('/api/fcm/index.php', {
        work: 'TOKEN',
        _method: 'PUT',
        deviceID,
        notification: enabled ? 'ON' : 'OFF'
    });
};
