import {
    createActionCreators,
    createReducerFunction,
    ImmerReducer
} from 'immer-reducer';

import Status from '~/constants/Status';
import FCMData from '~/types/FCMData';

interface ManageFCMModuleState {
    status: Status;
    data: FCMData;
    enabled: boolean;
}

const initializeState: ManageFCMModuleState = {
    status: Status.INITIAL,
    enabled: false,
    data: {
        token: null,
        deviceID: null,
        locale: null,
        osVersion: null,
        appVersion: null,
        userInfo: null
    }
};

class ManageFCMModule extends ImmerReducer<ManageFCMModuleState> {
    reset() {
        this.draftState = initializeState;
    }

    initialize(
        initData: ManageFCMModuleState['data'],
        enabled: boolean,
        sync: boolean
    ) {
        if (this.draftState.status !== Status.INITIAL) return;

        this.draftState.data = initData;
        this.draftState.enabled = enabled;
    }

    syncFCMData() {}
    syncFCMDataSuccess(syncData: ManageFCMModuleState['data']) {
        if (this.draftState.status !== Status.SUCCESS) return;

        this.draftState.data = {
            ...syncData
        };
    }

    updateFCMData() {
        this.draftState.status = Status.LOADING;
    }
    updateFCMDataSuccess(
        newData: Partial<Omit<ManageFCMModuleState['data'], 'enabled'>>
    ) {
        if (this.draftState.status !== Status.LOADING) return;
        this.draftState.status = Status.SUCCESS;

        this.draftState.data = {
            ...this.draftState.data,
            ...newData
        };
    }

    updateEnabledWithPermission(enabled: boolean) {}
    updateEnabled(enabled: boolean) {}
    setEnabled(enabled: boolean) {
        this.draftState.enabled = enabled;
    }
}

export const manageFCMReducer = createReducerFunction(
    ManageFCMModule,
    initializeState
);
export const manageFCMAction = createActionCreators(ManageFCMModule);
