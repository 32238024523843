import React, { FC, useEffect } from 'react';

const Footer: FC = () => {
    useEffect(() => {
        const script = document.createElement('script');

        script.innerHTML = 'oFooter.getSubFooter();';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null;
};

export default Footer;
