import Cookies from 'js-cookie';
import { putWait, withCallback } from 'redux-saga-callback';
import { all, call, put, select, take, takeLatest } from 'typed-redux-saga';

import { fetchTokenAPI } from '~/apis/fcm';
import {
    changeFCMEnabledAPI,
    syncFCMDataApi,
    updateFCMDataAPI
} from '~/apis/notification/token';
import LocalStorageKey from '~/constants/LocalStorageKey';
import NotificationPermission from '~/constants/NotificationPermission';
import Status from '~/constants/Status';
import detectBrowser from '~/libs/detectBrowser';
import errorHandler from '~/libs/errorHandler';
import getUserLocale from '~/libs/getUserLocale';
import makeDeviceID from '~/libs/makeDeviceID';
import { RootReducerState } from '~/modules';
import { allowFCMAction } from '~/modules/AllowFCMModule';
import { localStorageAction } from '~/modules/LocalStorageModule';
import { manageFCMAction } from '~/modules/ManageFCMModule';

/**
 * initialize token reducer
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* InitializeSaga(): Generator {
    type InitializeActionType = ReturnType<typeof manageFCMAction.initialize>;

    const {
        payload: [, , sync]
    } = yield* take<InitializeActionType>(manageFCMAction.initialize.type);

    try {
        // sync 시 token 조회
        if (sync) yield put(manageFCMAction.syncFCMData());
        // 그 외 일반 초기화
        else yield put(manageFCMAction.updateFCMData());
    } catch (error) {
        errorHandler(error, 'error occurred in manage token initialize saga.');
    }
}

/**
 * 서버와 fcm data 동기화
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* SyncFCMDataSaga(): Generator {
    type SyncFCMDataActionType = ReturnType<typeof manageFCMAction.syncFCMData>;

    yield takeLatest<SyncFCMDataActionType>(
        manageFCMAction.syncFCMData.type,
        function* () {
            const {
                manageFCMReducer: {
                    status,
                    data: { deviceID, userInfo }
                }
            } = (yield* select()) as RootReducerState;

            // DeviceID 가 없을 경우, 신규 진입으로 FCM 데이터 초기화
            if (!deviceID) {
                yield* put(manageFCMAction.updateFCMData());
                return;
            }

            try {
                // sync api 호출
                const response = yield* call(syncFCMDataApi, deviceID);

                const fcmData = {
                    deviceID,
                    appVersion: response?.app_version || null,
                    osVersion: response?.os_version || null,
                    token: response?.token || null,
                    locale: response?.locale || null,
                    enabled: response?.notification === 'ON',
                    userInfo
                };

                // data sync
                yield* put(manageFCMAction.syncFCMDataSuccess(fcmData));
                yield* put(
                    localStorageAction.setItems({
                        [LocalStorageKey.FCM_TOKEN]: fcmData.token,
                        [LocalStorageKey.NOTI_LOCALE]: fcmData.locale,
                        [LocalStorageKey.NOTI_OS_VERSION]: fcmData.osVersion,
                        [LocalStorageKey.NOTI_APP_VERSION]: fcmData.appVersion,
                        [LocalStorageKey.NOTI_ENABLED]:
                            fcmData.enabled.toString()
                    })
                );

                // 데이터 재 갱신
                yield* put(manageFCMAction.updateFCMData());
            } catch (error) {
                errorHandler(error, 'error occurred in sync fcm data saga.');
            }
        }
    );
}

/**
 * update fcm data
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateFCMDataSaga(): Generator {
    type UpdateFCMDataActionType = ReturnType<
        typeof manageFCMAction.updateFCMData
    >;

    yield takeLatest<UpdateFCMDataActionType>(
        manageFCMAction.updateFCMData.type,
        withCallback(function* () {
            // 현재 state 값 조회
            const result = yield* select(
                ({ manageFCMReducer, allowFCMReducer }: RootReducerState) => {
                    if (
                        allowFCMReducer.status !== Status.SUCCESS ||
                        !allowFCMReducer.data
                    )
                        return false;

                    const { support, permission } = allowFCMReducer.data;
                    const allow =
                        support &&
                        permission === NotificationPermission.GRANTED;

                    return { fcmData: manageFCMReducer.data, allow };
                }
            );

            if (!result) return;

            // 값 조회
            const {
                fcmData: {
                    token,
                    deviceID,
                    locale,
                    osVersion,
                    appVersion,
                    userInfo
                },
                allow
            } = result;

            try {
                // 값을 전달받지 않았을 경우 기존 값 세팅
                const newDeviceID = deviceID || makeDeviceID();
                const newLocale = getUserLocale();

                // 브라우저 정보 조회
                const browser = detectBrowser();
                let newOsVersion = osVersion,
                    newAppVersion = appVersion;

                if (browser) {
                    newOsVersion = browser.os;
                    newAppVersion = browser.name + ' ' + browser.version;
                }

                // token 조회
                let newToken = token;
                if (allow) newToken = yield* call(fetchTokenAPI);

                // 유저 현재 정보 조회
                const newUserInfo = Cookies.get('PdboxTicket') || null;

                // 기존 값과 동일한지 확인
                if (
                    deviceID === newDeviceID &&
                    token === newToken &&
                    locale === newLocale &&
                    osVersion === newOsVersion &&
                    appVersion === newAppVersion &&
                    userInfo === newUserInfo
                ) {
                    // reducer update
                    yield put(manageFCMAction.updateFCMDataSuccess({}));
                    return;
                }

                // register api 호출
                if (allow) {
                    yield call(updateFCMDataAPI, {
                        deviceID: newDeviceID,
                        token: newToken || '',
                        locale: newLocale,
                        osVersion: newOsVersion || '',
                        appVersion: newAppVersion || ''
                    });
                }

                // reducer update
                yield put(
                    manageFCMAction.updateFCMDataSuccess({
                        deviceID: newDeviceID,
                        token: newToken,
                        locale: newLocale,
                        osVersion: newOsVersion,
                        appVersion: newAppVersion,
                        userInfo: newUserInfo
                    })
                );

                yield put(
                    localStorageAction.setItems({
                        [LocalStorageKey.NOTI_DEVICE_ID]: newDeviceID,
                        [LocalStorageKey.FCM_TOKEN]: newToken,
                        [LocalStorageKey.NOTI_LOCALE]: newLocale,
                        [LocalStorageKey.NOTI_OS_VERSION]: newOsVersion,
                        [LocalStorageKey.NOTI_APP_VERSION]: newAppVersion,
                        [LocalStorageKey.NOTI_USER_INFO]: newUserInfo
                    })
                );
            } catch (error) {
                errorHandler(
                    error,
                    'error in manage token update fcm data saga.'
                );
            }
        })
    );
}

/**
 * 알림 권한 체크 후 알림 활성화 업데이트
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateEnabledWithPermissionSaga(): Generator {
    type UpdateEnabledWithPermissionActionType = ReturnType<
        typeof manageFCMAction.updateEnabledWithPermission
    >;

    while (true) {
        const { payload: enabled } =
            yield* take<UpdateEnabledWithPermissionActionType>(
                manageFCMAction.updateEnabledWithPermission.type
            );

        // reducer 데이터 조회
        const result = yield* select(
            ({ allowFCMReducer }: RootReducerState) => {
                if (
                    allowFCMReducer.status !== Status.SUCCESS ||
                    !allowFCMReducer.data
                )
                    return false;

                return allowFCMReducer.data;
            }
        );

        if (!result) continue;

        // 알림 권한 체크
        const { permission } = result;
        if (enabled && permission === NotificationPermission.DEFAULT) {
            yield putWait(allowFCMAction.checkAllow(true));
        }

        yield* put(manageFCMAction.updateEnabled(enabled));
    }
}

/**
 * update notification enable
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* UpdateEnabledSaga(): Generator {
    type UpdateEnabledActionType = ReturnType<
        typeof manageFCMAction.updateEnabled
    >;

    while (true) {
        const { payload: enabled } = yield* take<UpdateEnabledActionType>(
            manageFCMAction.updateEnabled.type
        );

        // reducer 값 조회
        const result = yield* select(
            ({ manageFCMReducer, allowFCMReducer }: RootReducerState) => {
                if (
                    manageFCMReducer.status !== Status.SUCCESS ||
                    allowFCMReducer.status !== Status.SUCCESS ||
                    !allowFCMReducer.data
                )
                    return false;

                return {
                    manageFCMData: manageFCMReducer.data,
                    enabled: manageFCMReducer.enabled,
                    allowFCMData: allowFCMReducer.data
                };
            }
        );

        if (!result) continue;

        const { deviceID } = result.manageFCMData;
        const storedEnable = result.enabled;
        const { permission, support } = result.allowFCMData;

        try {
            const newEnabled =
                enabled &&
                support &&
                permission === NotificationPermission.GRANTED;

            // 값 확인
            if (storedEnable === newEnabled || !deviceID) continue;

            // fcm data 갱신
            yield putWait(manageFCMAction.updateFCMData());

            // 활성화 여부 변경 요청
            yield call(changeFCMEnabledAPI, deviceID, newEnabled);

            yield all([
                // reducer 에 enable 값 적용
                put(manageFCMAction.setEnabled(newEnabled)),

                // localstorage 에 데이터 재설정
                put(
                    localStorageAction.setItem(
                        LocalStorageKey.NOTI_ENABLED,
                        newEnabled.toString()
                    )
                )
            ]);
        } catch (error) {
            errorHandler(
                error,
                'error occurred in manage token update enabled saga.'
            );
        }
    }
}

/**
 * manage token saga
 *
 * @returns {Generator} saga generator
 * @yields
 */
function* ManageFCMSaga(): Generator {
    yield* all([
        call(InitializeSaga),
        call(UpdateFCMDataSaga),
        call(UpdateEnabledSaga),
        call(UpdateEnabledWithPermissionSaga),
        call(SyncFCMDataSaga)
    ]);
}

export default ManageFCMSaga;
