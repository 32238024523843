import qs from 'qs';

import { LIVE_HOST } from '~/constants/hosts';
import LogName from '~/constants/LogName';
import axios from '~/libs/axios';

export interface PRECVLogData {
    pushKey: string;
    alarmCode: string;
    commonNo?: number;
    giftCount?: number;
}

export interface AlaramClickLogData {
    codeType: 'pc_alaram_click';
    pushKey: string;
    alarmCode: string;
    clickUrl?: string;
    commonNo?: number;
    giftCount?: number;
}

export interface EnableClickLogData {
    codeType: 'pc_alaram_chg_status';
    enable: boolean;
    permission: NotificationPermission;
}

type PRECVLogParam = [LogName.PRECV, PRECVLogData];
type ClickLogParam = [LogName.CLICK, AlaramClickLogData];
type EnableClickLogParam = [LogName.CLICK, EnableClickLogData];

type CollectorLogAPIType = (
    ...args: PRECVLogParam | ClickLogParam | EnableClickLogParam
) => Promise<void>;

export const collectorLogAPI: CollectorLogAPIType = async (
    logName,
    logData
) => {
    await axios.post('/api/fcm/index.php', {
        work: 'COLLECTOR_LOG',
        logName,
        logData: JSON.stringify(logData)
    });
};

export const collectorLogBackendAPI: CollectorLogAPIType = async (
    logName,
    logData
) => {
    await fetch(`${LIVE_HOST}/api/fcm/index.php`, {
        mode: 'cors',
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: qs.stringify({
            work: 'COLLECTOR_LOG',
            logName,
            logData: JSON.stringify(logData)
        })
    });
};
