import debug from 'debug';

import ResponseError from '~/errors/ResponseError';
import axios from '~/libs/axios';
import { ContentsSettingData } from '~/types/ContentsSettingData';
import APIResponse from '~/types/response/APIResponse';
import {
    ContentsSettingResponse,
    isContentsSettingResponse
} from '~/types/response/ContentsSettingResponse';
import ResetSettingResponse, {
    isResetSettingResponse
} from '~/types/response/ResetSettingResponse';

const settingDebug = debug('noti:setting-api');

/**
 * 콘텐츠 설정 조회
 *
 * @param {string} deviceID 조회할 deviceID
 * @returns {Promise<ContentsSettingResponse>} axios response
 */
export const fetchContentsSettingAPI = async (
    deviceID: string
): Promise<ContentsSettingResponse> => {
    settingDebug('fetchContentsSettingAPI', deviceID);
    const { data: response } = await axios.get<
        APIResponse<ContentsSettingResponse>
    >('/api/fcm/index.php', {
        params: {
            work: 'SETTING',
            deviceID
        }
    });

    // 지정한 데이터가 맞는지 확인
    if (!isContentsSettingResponse(response.DATA)) {
        throw new ResponseError(
            'Mismatch responses in fetch contents setting api.',
            response
        );
    }

    return response.DATA;
};

/**
 * 콘텐츠 초기 세팅 조회
 *
 * @returns {Promise<ResetSettingResponse>} axios response
 */
export const resetContentsSettingAPI =
    async (): Promise<ResetSettingResponse> => {
        settingDebug('resetContentsSettingAPI');
        const { data: response } = await axios.get<
            APIResponse<ResetSettingResponse>
        >('/api/fcm/index.php', {
            params: {
                work: 'SETTING_RESET'
            }
        });

        if (!isResetSettingResponse(response.DATA)) {
            throw new ResponseError(
                'Mismatch responses in reset contents setting api.',
                response
            );
        }

        return response.DATA;
    };

interface UpdateContentsSettingAPIFCMData {
    deviceID: string;
    token: string;
    locale: string;
    osVersion: string;
    appVersion: string;
}

/**
 * 콘텐츠 설정 변경 변경
 *
 * @param {UpdateContentsSettingAPIFCMData} data fcm data
 * @param {string} settings 변경할 setting 정보
 */
export const updateContentsSettingAPI = async (
    data: UpdateContentsSettingAPIFCMData,
    settings: Partial<ContentsSettingData>
) => {
    settingDebug('updateContentsSettingAPI', data, settings);
    await axios.post('/api/fcm/index.php', {
        work: 'SETTING',
        _method: 'PUT',
        settings: JSON.stringify(settings),
        ...data
    });
};
