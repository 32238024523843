import StorageError from '~/errors/StorageError';

export interface Storage {
    getItem: (key: string) => string | null;
    removeItem(key: string): void;
    setItem(key: string, value: string): void;
}

let storage: Storage | null = null;
const getStorage = (): Storage => {
    if (storage) return storage;

    const newStorage: Storage = {
        removeItem: (key) => {},
        getItem: (key) => '',
        setItem: (key, value) => {}
    };

    try {
        const localStorage = window.localStorage;

        newStorage.setItem = (key, value) => localStorage.setItem(key, value);
        newStorage.getItem = (key) => localStorage.getItem(key);
        newStorage.removeItem = (key) => localStorage.removeItem(key);
    } catch (error) {
        if (!(error instanceof Error)) return newStorage;

        throw new StorageError(error.message);
    }

    storage = newStorage;
    return newStorage;
};

export default getStorage;
