enum TranslateLocales {
    KO = 'ko',
    EN = 'en',
    TH = 'th',
    ZH_CN = 'zh_CN',
    ZH_TW = 'zh_TW',
    JA = 'ja'
}

export default TranslateLocales;

export const getAllowLocales = () => [
    TranslateLocales.KO,
    TranslateLocales.EN,
    TranslateLocales.TH,
    TranslateLocales.ZH_CN,
    TranslateLocales.ZH_TW,
    TranslateLocales.JA
];
