import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';

import { allowFCMReducer } from '~/modules/AllowFCMModule';
import { contentsSettingReducer } from '~/modules/ContentsSettingModule';
import { localStorageReducer } from '~/modules/LocalStorageModule';
import { manageFCMReducer } from '~/modules/ManageFCMModule';
import rootSaga from '~/sagas';

// combined reducer 세팅
export const combinedReducer = combineReducers({
    localStorageReducer,
    manageFCMReducer,
    allowFCMReducer,
    contentsSettingReducer
});

export type RootReducerState = ReturnType<typeof combinedReducer>;

// sagaMiddleware 생성
const sagaMiddleware = createSagaMiddleware();

// store 생성
const store = createStore(
    combinedReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

// saga 실행
sagaMiddleware.run(rootSaga);

export default store;
